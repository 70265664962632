.InfoContainer {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    overflow: scroll;
    height: auto;
    animation: ease-in-out .2s;
}

.InfoContainer * {
    padding: .25rem 0;
    transition: ease-in-out .2s;
}

.InfoContainer h1 {
    font-weight: 400;
    font-size: 3rem;
}

.InfoContainer h2 {
    font-weight: 400;
    font-size: 2.25rem;
}

.InfoContainer h3 {
    font-weight: 300;
    font-size: 2rem;
}

.InfoContainer p {
    font-weight: 300;
    font-size: 1.5rem;
}

.InfoContainer .break {
    flex-basis: 100%;
    height: 0;
}

.InfoContainer hr {
    padding: 0;
    flex-basis: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.HoverableList {
    padding: .5rem;
    border-radius: .5rem;
    cursor: help;
    display: flex;
}

.InlineText {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
}

.InlineText * {
    width: 32rem;
}

.InlineText .Left {
    margin-right: 5vw;
    text-align: left;
}

.InlineText .Right {
    margin-left: 5vw;
    text-align: right;
}

.HoverableList .ToolTip {
    visibility: visible;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.HoverableList:hover .ToolTip {
    visibility: visible;
}

.HoverableList:hover {
    background-color: #aa00ff;
    border-radius: 1rem;
}

@media (min-aspect-ratio: 5/5) {
    .InfoContainer {
        margin-left: 20vw;
        margin-right: 20vw;
    }

    /* .InlineText {
        width: 60%;
        margin: 0 2rem;
    } */
}

@media only screen and (max-width: 600px) {
    .InlineText {
        justify-content: space-between;
        width: 100%;
    }

    .InlineText * {
        margin: 0;
        width: auto;
    }
}