@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900");

* {
    padding: 0px;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
    -ms-overflow-style: none;
    overflow: hidden;
    z-index: 1;
}

.App {
    left: 0;
    top: 0;
    max-width: 100vw;
    min-height: 100vh;
    position: absolute;
    text-align: center;
    overflow: hidden;
    padding: 0px;
    background-color: #2C2F33;
    color: #2C2F33;
}


::-webkit-scrollbar {
    display: none;
}

.body {
    padding-bottom: 0px;
    scrollbar-width: none;
}